import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import { Fragment, useState, useRef } from "react";

import * as XLSX from "xlsx/xlsx.mjs";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import useAuthContext from "../hooks/useAuthContext";

import { exportOrders } from "../services";

import _ from "lodash";

function ModalExportOrder() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [foundOrders, setFoundOrders] = useState([]);
  const [error, setError] = useState(false);

  const { authentication } = useAuthContext();

  const theme = useTheme();

  const exportOrderAsExcel = (orderList) => {
    // console.log("trigger", orderList);
    if (!orderList || _.isEmpty(orderList)) {
      return;
    }

    const formattedOrderList = orderList.map((order) => ({
      "Customer Code": order?.customerCode || "",
      "Customer Order ID": order?.customerOrderId || "",
      "Ordered By": order?.orderedBy || "",
      "Order Date": order?.orderDate || "",
      "Tracking Number": order?.trackingNum || "",
      "Parent Tracking Number": order?.parentTrackingNum || "",
      "Service Code": order?.serviceCode || "",
      "Estimated Amount": order?.estimated_amount || "",
      Zone: order?.packageInfos?.zone || "",
      "Billing Weight": order?.packageInfos?.billingWeight || "",
      "Actual Weight": order?.packageInfos?.actualWeight || "",
      "Dim Weight": order?.packageInfos?.dimWeight || "",
      "Base Rate": order?.packageInfos?.baseRate || "",
      "Total Rate": order?.packageInfos?.totalRate || "",
      Oversize: order?.packageInfos?.oversize || "",
      "Oversize Peak": order?.packageInfos?.oversizePeak || "",
      AHS: order?.packageInfos?.AHS || "",
      "AHS Weight": order?.packageInfos?.AHSWeight || "",
      "AHS Peak": order?.packageInfos?.AHSPeak || "",
      "AHS Dimensions": order?.packageInfos?.AHSDimensions || "",
      "Ground Unauthorized": order.packageInfos?.groundUnauthorized || "",
      ODA: "",
      Fuel: order?.packageInfos?.fuel || "",
      Residential: order?.packageInfos?.residential || "",
      "Residential Peak": order?.packageInfos?.residentialPeak || "",
      "Direct Signature": order?.packageInfos?.directSignature || "",
      "Adult Signature": order?.packageInfos?.adultSignature || "",
      "Delivery Area Surcharge": order?.packageInfos?.deliveryRemote || "",
      "DAS Commercial": order?.packageInfos?.DASCommercial || "",
      "DAS Extended Commercial":
        order?.packageInfos?.DASExtendedCommercial || "",
      "DAS Residential": order?.packageInfos?.DASResidential || "",
      "DAS Extended Residential":
        order?.packageInfos?.DASExtendedResidential || "",
      "Delivery Area Surcharge Residential":
        order?.packageInfos?.DeliveryASResidential || "",
      "Delivery Area Surcharge Extended Residential":
        order?.packageInfos?.DeliveryASExtendedResidential || "",
      "Delivery Area Surcharge Commercial":
        order?.packageInfos?.DeliveryASCommercial || "",
      "Delivery Area Surcharge Extended Commercial":
        order?.packageInfos?.DeliveryASExtendedCommercial || "",
      "Delivery Area Surcharge Remote Commercial":
        order?.packageInfos?.DeliveryASRemoteCommercial || "",
      "Delivery Area Surcharge Remote Residential":
        order?.packageInfos?.DeliveryASRemoteResidential || "",
      "Delivery Area Surcharge Hawaii Residential":
        order?.packageInfos?.DeliveryASHawaiiResidential || "",
      "NDOC P/U- Auto Comm": order?.packageInfos?.ndocComm || "",
      Length: order?.packageInfos?.length || "",
      Width: order?.packageInfos?.width || "",
      Height: order?.packageInfos?.height || "",
      "Order Status": order?.orderStatus || "",
      "Refund Status": order?.refundStatus || "",
      "Sender Warehouse Code": order?.sender?.warehouseCode || "",
      "Sender Name": order?.sender?.name || "",
      "Sender Country": order?.sender?.country || "",
      "Sender Province": order?.sender?.province || "",
      "Sender City": order?.sender?.city || "",
      "Sender Address": order?.sender?.address || "",
      "Sender Zip Code": order?.sender?.zipCode || "",
      "Sender Mobile": order?.sender?.mobile || "",
      "Receiver Name": order?.receiver?.name || "",
      "Receiver Country": order?.receiver?.country || "",
      "Receiver Province": order?.receiver?.province || "",
      "Receiver City": order?.receiver?.city || "",
      "Receiver Address": order?.receiver?.address || "",
      "Receiver Address2": order?.receiver?.address2 || "",
      "Receiver Address3": order?.receiver?.address3 || "",
      "Receiver Zip Code": order?.receiver?.zipCode || "",
      "Receiver Mobile": order?.receiver?.mobile || "",
      "Receiver Email": order?.receiver?.email || "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedOrderList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "exported_orders.xlsx");
  };

  const setStartDateToMidnightUTC = (date) => {
    return new Date(date.setHours(0, 0, 0, 0)).toISOString();
  };

  const setEndDateTo2359UTC = (date) => {
    return new Date(date.setHours(23, 59, 59, 999)).toISOString();
  };

  const handleConfirm = () => {
    // format start day
    const originalStartDate = new Date(startDate);
    const formattedStartDate = setStartDateToMidnightUTC(originalStartDate);
    // console.log("format s", formattedStartDate);

    // format end day
    const originalEndDate = new Date(endDate);
    const formattedEndDate = setEndDateTo2359UTC(originalEndDate);
    // console.log("format e", formattedEndDate);

    exportOrders(authentication, formattedStartDate, formattedEndDate)
      .then((response) => {
        if (response.data.list && !_.isEmpty(response.data.list)) {
          setError(false);
          setFoundOrders(response.data.list);
          return response.data.list;
        } else {
          setError(true);
        }
      })
      .then((list) => exportOrderAsExcel(list))
      .catch((error) => {
        // console.log("entered2", error);
        setError(true);
      });

    // handleClose();
  };

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    boxShadow: 24,
    bgcolor: "white",
    p: 8,
  };

  return (
    <>
      <Button color="secondary" variant="contained" onClick={handleOpen}>
        Export orders
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <Grid container>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
                Select Range
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "2rem",
              }}
            >
              <Typography style={{ color: theme.palette.primary.main }}>
                Date From:
                <DatePicker
                  showIcon
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </Typography>
              <Typography style={{ color: theme.palette.primary.main }}>
                Date To:
                <DatePicker
                  showIcon
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {foundOrders.length > 0 && (
                <Alert severity="success" sx={{ marginBottom: "0.5rem" }}>
                  {`${foundOrders.length} orders found and exported`}
                </Alert>
              )}
              {error && (
                <Alert severity="error" sx={{ marginBottom: "0.5rem" }}>
                  No available orders found in this range
                </Alert>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              sx={{ marginTop: "2rem" }}
            >
              <Button variant="contained" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirm}
              >
                Export
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default ModalExportOrder;
